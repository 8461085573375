import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Templates are JSON documents that describe the most important fields in the final certificate or certificate request.
Used to customize x.509 or SSH certificates for any use case,
Templates allow you to add custom SANs or extensions:`}</p>
    <ul>
      <li parentName="ul">{`Customize certificates for a broad range of applications and integrations.`}</li>
      <li parentName="ul">{`Provides full control of all of certificate fields, even add custom extensions.`}</li>
      <li parentName="ul">{`Automate custom certificate generation and skip the manual workflows.`}</li>
    </ul>
    <h2>{`Learn More`}</h2>
    <p>{`Templates are an open-source feature.
Visit `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-ca/templates"
      }}>{`the `}<inlineCode parentName="a">{`step-ca`}</inlineCode>{` documentation`}</a>{` for a complete description of Templates and instructions on usage and capabilities.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      